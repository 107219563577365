import { render, staticRenderFns } from "./ATOMRewardsHtml.vue?vue&type=template&id=4787cefa&scoped=true"
import script from "./ATOMRewardsHtml.vue?vue&type=script&lang=js"
export * from "./ATOMRewardsHtml.vue?vue&type=script&lang=js"
import style0 from "./ATOMRewardsHtml.vue?vue&type=style&index=0&id=4787cefa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4787cefa",
  null
  
)

export default component.exports